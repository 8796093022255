/* Global styles available in all components of the voter portal app */

/* Utility imports */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "firebaseui/dist/firebaseui.css";
@import "font-awesome/css/font-awesome.css";

/* Default styling applied to HTML */
body { margin: 56px 0 0 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
section { margin: 0 10px; }

/* Material UI customization */
.mat-mdc-menu-item-text { font-size: 14px !important; }
.mat-mdc-card { padding: 16px; }
.mdc-button__label { letter-spacing: normal; }

/* TERPECA-specific style classes */
.terpeca-multiline { white-space: pre-line; }

/* fxFlex replacement */
.flex-default { flex: 1; } /* fxFlex */
.flex-noshrink { flex: 1 0 auto; } /* fxFlex="noshrink" */

/* fxLayout replacement */
.flex-row { display: flex; flex-direction: row; } /* fxLayout="row" */
.flex-row-reverse { display: flex; flex-direction: row-reverse; } /* fxLayout="row-reverse" */
.flex-col { display: flex; flex-direction: column; } /* fxLayout="column" */
.flex-col-reverse { display: flex; flex-direction: column-reverse; } /* fxLayout="column-reverse" */
.flex-wrap { flex-wrap: wrap; } /* fxLayout="xxx wrap" */

/* fxLayoutGap replacement */
.gap-0 { gap: 0px; } /* fxLayoutGap="0px" */
.gap-5 { gap: 5px; } /* fxLayoutGap="5px" */
.gap-10 { gap: 10px; } /* fxLayoutGap="10px" */
.gap-15 { gap: 15px; } /* fxLayoutGap="15px" */
.gap-25 { gap: 25px; } /* fxLayoutGap="25px" */
.gap-0-sm { @media screen and (max-width: 959px) { gap: 0px; } }  /* fxLayoutGap.sm="0px" */
.gap-10-gt-sm { @media screen and (min-width: 960px) { gap: 10px; } } /* fxLayoutGap.gt-sm="10px" */

/* fxLayoutAlign replacement */
.justify-center { justify-content: center; } /* fxLayoutAlign="center xxx" */
.justify-start { justify-content: start; } /* fxLayoutAlign="start xxx" */
.justify-space-around { justify-content: space-around; }  /* fxLayoutAlign="space-around xxx" */
.justify-space-between { justify-content: space-between; }  /* fxLayoutAlign="space-between xxx" */
.justify-flex-start { justify-content: flex-start; }  /* fxLayoutAlign="flex-start xxx" */
.justify-flex-end { justify-content: flex-end; }  /* fxLayoutAlign="flex-end xxx" */
.align-center { align-items: center; } /* fxLayoutAlign="xxx center" */
.align-start { align-items: start; } /* fxLayoutAlign="xxx start" */
.align-end { align-items: end; } /* fxLayoutAlign="xxx end" */
.align-stretch { align-items: stretch; } /* fxLayoutAlign="xxx stretch" */

/* fxHide replacement */
.hide-sm    { @media screen and (max-width: 959px) { display: none; } } /* fxHide.sm="true" */
.hide-gt-sm { @media screen and (min-width: 960px) { display: none; } } /* fxHide.gt-sm="true" */
.hide-md    { @media screen and (max-width: 1279px) { display: none; } } /* fxHide.md="true" */
.hide-gt-md { @media screen and (min-width: 1280px) { display: none; } } /* fxHide.gt-md="true" */
